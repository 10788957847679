<script>
	import { _ } from 'svelte-i18n';
	import Slider from '@bulatdashiev/svelte-slider';
	import { createEventDispatcher } from 'svelte';
	import Cropper from 'svelte-easy-crop';
	import { getCroppedImg } from '$utils/canvas.js';
	import { notification } from '$src/stores.js';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import FullPageModal from './FullPageModal.svelte';
	import MagnifyIcon from '$lib/icon/MagnifyIcon.svelte';
	import MinifyIcon from '$lib/icon/MinifyIcon.svelte';

	const dispatch = createEventDispatcher();

	let value = [1, 2];
	export let imgSrc;
	export let ajaxRequest = false;
	export let type = 'picture';

	let crop = { x: 0, y: 0 };
	$: zoom = value[0];

	let croppedImage;
	let pixelCrop;
	async function cropImage() {
		croppedImage = await getCroppedImg(imgSrc, pixelCrop, type);
	}

	function previewCrop(e) {
		pixelCrop = e.detail.pixels;
	}
</script>

<FullPageModal on:close={() => dispatch('cancel')}>
	<div class="relative h-96 w-full">
		<Cropper
			image={imgSrc}
			bind:crop
			bind:zoom
			aspect={type == 'banner' ? 3 / 1 : 1}
			showGrid={false}
			on:cropcomplete={previewCrop}
		/>
	</div>

	<div class="flex items-center w-full">
		<MinifyIcon />
		<Slider bind:value min={1} max={2} step={0.1} />
		<MagnifyIcon />
	</div>

	<div class="flex items-center justify-between w-full mt-8">
		<button on:click={() => dispatch('cancel')} class="focus:underline hover:underline w-24"
			>{$_('Cancel')}</button
		>
		<button
			disabled={ajaxRequest}
			on:click={async () => {
				try {
					ajaxRequest = true;
					await cropImage();
					dispatch('upload', croppedImage);
				} catch (err) {
					console.error(err);
					ajaxRequest = false;
					notification.show($_('Something went wrong. Please try again later.'), 'error');
				}
			}}
			class="btn-background w-32 h-9 inline-flex items-center justify-center"
		>
			{#if ajaxRequest}
				<SpinnerIcon css="h-5 w-5" />
			{:else}
				{$_('OK')}
			{/if}
		</button>
	</div>
</FullPageModal>
